import {
  compose,
  combineReducers,
  AnyAction,
  MiddlewareAPI,
  Dispatch,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { safeLocalStorage, safeSessionStorage } from 'shared/utils/safeStorage';
import { configureStore } from '@reduxjs/toolkit';

import groups from 'shared/reducers/groups';
import notifications from 'shared/reducers/notifications';
import userConsents from 'shared/reducers/userConsents';
import users from 'shared/reducers/users';
import locale from 'shared/reducers/locale';
import company from 'shared/reducers/company';
import categories from 'shared/reducers/categories';
import mobile from 'shared/reducers/mobile';
import sidenav from 'shared/reducers/sidenav';
import userData, { userDataStatus } from 'shared/reducers/userData';
import products from 'shared/reducers/products';
import cities from 'shared/reducers/cities';
import icons from 'shared/reducers/icons';
import ui, { UiState } from 'shared/reducers/ui';
import bonuses from 'shared/reducers/bonuses';
import accounts from 'shared/reducers/accounts';
import companies from 'shared/reducers/companies';
import orders from 'shared/reducers/orders';
import dashboard from 'shared/reducers/dashboard/tiles';
import cart from 'shared/reducers/cart/cart';
import employees from 'shared/reducers/employees';
import employeeData from 'shared/reducers/employeeData';
import grades from 'shared/reducers/grades';
import whipRounds from 'shared/reducers/whipRounds';
import registration from 'shared/reducers/registration';
import websocket from 'shared/modules/notifications/websocket/websocketStore';
import purchases from 'shared/reducers/purchases';
import employeeBenefits, {
  EmployeeBenefitsState,
} from 'shared/reducers/employeeBenefits';
import searchHistory from 'shared/reducers/searchHistory';
import makeInjectableHtmlReducer from 'shared/reducers/makeInjectableHtmlReducer';
import guideSets from 'shared/modules/guides/guideSetsReducer';
import persistState, {
  mergePersistedState,
  actionTypes,
} from 'redux-localstorage';
import userCompany, {
  UserCompanyState,
} from 'shared/reducers/users/userCompany';
import CartState from 'shared/types/reducers/CartState';
import { InjectableHTML } from 'shared/types/InjectableHTML';
import { GuideSetsState } from 'shared/types/Guides';
import employeeList from 'shared/modules/employees/employeeListStore';
import kudosConfig from 'shared/reducers/kudosConfig';
import challengeCompanyGroup from 'shared/reducers/challengeCompanyGroup';
import kudos from 'shared/reducers/kudos';
import Purchase from 'shared/types/Purchase';

import { AppState } from 'shared/types/AppState';
import commentsReducer from 'shared/modules/comments/commentsReducer';
import { GroupsState } from 'shared/types/Groups';
import consentReducer from 'shared/modules/consents/consentReducer';
import tenorReducer from 'shared/modules/tenor/tenorStore';
import submitsReducer from 'shared/modules/documents/submitsStore';
import surveyReducer from 'shared/modules/surveys/surveysStore';
import newsReducer from 'shared/modules/news/newsStore';
import stripeReducer from 'shared/modules/stripe/stripeStore';
import challengesReducer from 'shared/modules/challenges/challengesStore';
import insightsReducer from 'shared/modules/insights/insightsStore';
import documentsReducer from 'shared/modules/documents/documentsStore';
import processReducer from 'shared/modules/process/processStore';
import budgetReducer from 'shared/modules/budgets/budgetStore';

import posts from 'shared/modules/posts/postsStore';
import feedback from 'shared/modules/feedback/feedbackStore';
import sections from 'shared/modules/sections/sectionsStore';

import multiAdapter from './multiAdapter';

export interface WebState extends AppState {
  cart: CartState;
  ui: UiState;
  tileCatalog: InjectableHTML[];
  mobile: boolean;
  employeeBenefits: EmployeeBenefitsState;
  activePurchase: Purchase;
  groups: GroupsState;
  guideSets: GuideSetsState;
  stripe: ReturnType<typeof stripeReducer>;
  userCompany: UserCompanyState;
}

const reducer = combineReducers({
  ui,
  users,
  budget: budgetReducer,
  groups,
  userConsents,
  products,
  company,
  employees,
  employeeData,
  cities,
  mobile,
  userDataStatus,
  employeeBenefits,
  employeeList,
  icons,
  sidenav,
  posts,
  tenor: tenorReducer,
  locale,
  userData,
  notifications,
  searchHistory,
  categories,
  consents: consentReducer,
  accounts,
  companies,
  bonuses,
  challenges: challengesReducer,
  challengeCompanyGroup,
  documents: documentsReducer,
  purchases,
  dashboard,
  submits: submitsReducer,
  cart,
  insights: insightsReducer,
  grades,
  process: processReducer,
  whipRounds,
  registration,
  websocket,
  guideSets,
  surveys: surveyReducer,
  news: newsReducer,
  orders,
  comments: commentsReducer,
  tileCatalog: makeInjectableHtmlReducer('tileCatalog'),
  kudos,
  kudosConfig,
  userCompany,
  stripe: stripeReducer,
  feedback,
  sections,
});

const rootReducer = (
  state: ReturnType<typeof reducer> | undefined,
  action: AnyAction,
) => {
  if (action.type === 'USER_LOGOUT') {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const storageMap = [
  // localStore items
  ['cart', 'icons'],
  // sessionStore items
  [],
];

const storage = multiAdapter(
  [safeLocalStorage, safeSessionStorage],
  storageMap,
);

const persistedReducer = compose(mergePersistedState())(rootReducer);

const persistMiddleware =
  (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (action: any) => {
    const result = next(action);
    const nextState = store.getState();
    if (action.type === 'USER_LOGOUT') {
      storage.del('state-storage', () => {});
      return result;
    }
    if (action.type !== actionTypes.INIT) {
      storage.put('state-storage', nextState, () => {}, true);
    }
    return result;
  };

const middleware = [thunk, persistMiddleware];

export const getStore = () => {
  // @ts-ignore
  if (!window.IS_SSR) {
    return configureStore({
      reducer: persistedReducer,
      middleware,
      // @ts-ignore
      enhancers: [persistState(storage, 'state-storage')],
    });

    // createPersistentStore(
    //   persistedReducer,
    //   composeEnhancers(applyMiddleware(...middleware)),
    // );
  }
  return configureStore({
    reducer: persistedReducer,
    // @ts-ignore
    middleware: [thunk],
  });
};

// @ts-ignore
const store = getStore() as Store<AppState, any>;

export default store;
