import { combineReducers } from 'redux';
import { Action } from 'redux-actions';

import { PaymentMethod } from '@stripe/stripe-js';

import makeStatusReducer from 'shared/reducers/status';
import {
  BasicStripeCustomer,
  fetchStripeCustomerNames,
  fetchStripeCardsNames,
  FetchStripeCustomerSuccess,
  PatchStripeCustomer,
  FetchStripeCardsSuccess,
  patchStripeCustomerName,
  stripeCardDetachName,
  StripeCardDetach,
} from './stripeActions';

const customerData = (
  state: BasicStripeCustomer | null = null,
  action: Action<any>,
) => {
  if (
    action.type === fetchStripeCustomerNames.success ||
    action.type === patchStripeCustomerName
  ) {
    const { payload } = action as
      | FetchStripeCustomerSuccess
      | PatchStripeCustomer;
    return payload.data;
  }
  return state;
};

const cardsData = (state: PaymentMethod[] = [], action: Action<any>) => {
  if (action.type === fetchStripeCardsNames.success) {
    const { payload } = action as FetchStripeCardsSuccess;
    return payload.data;
  }

  if (action.type === stripeCardDetachName) {
    const { payload } = action as StripeCardDetach;
    return state.filter(item => item.id !== payload.method.id);
  }
  return state;
};

const cards = combineReducers({
  data: cardsData,
  status: makeStatusReducer(fetchStripeCardsNames),
});

const customer = combineReducers({
  data: customerData,
  status: makeStatusReducer(fetchStripeCustomerNames),
});

const stripeReducer = combineReducers({
  customer,
  cards,
});

export type StripeState = ReturnType<typeof stripeReducer>;

export default stripeReducer;
