/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppRouting, RoutingParams } from 'shared/utils/AppRouting';
import { combineReducers } from 'redux';
import { ProcessStatus } from 'shared/types/Document';
import { RouteNames } from 'shared/types/RouteNames';
import createAsyncThunkStatusSlice from 'shared/utils/redux/createAsyncThunkStatusSlice';
import {
  ProcessFlow,
  ProcessTemplate,
  TemplatesResponse,
} from './ProcessTypes';

export enum FetchMode {
  company = 'company',
  submit = 'submit',
}

const apiUrls: Record<FetchMode, RouteNames> = {
  company: 'api_process_company_templates_list',
  submit: 'api_process_templates_list',
};

interface FetchTemplatesParams {
  companyId?: number | null;
  employeeId?: number;
  mode: FetchMode;
  refresh?: boolean;
  forEmployee?: boolean;
  companies?: string[];
}

export const fetchProcessTemplates = createAsyncThunk(
  'templates/fetch',
  async ({
    companyId,
    mode,
    employeeId,
    forEmployee,
    companies,
  }: FetchTemplatesParams) => {
    const url = apiUrls[mode];
    const opts: RoutingParams = {};

    if (companyId) {
      opts.company = companyId;
    }

    if (forEmployee) {
      opts.submit_for_employee = 1;
    }

    if (employeeId) {
      opts.employee = employeeId;
    }

    if (companies?.length) {
      opts.companies = companies.join();
    }

    const response = await axios.get<TemplatesResponse>(
      AppRouting.generate(url, opts),
    );
    return response.data;
  },
);

const initialTemplateState: Record<string, ProcessTemplate> = {};

export const getTemplatesKey = ({
  mode,
  companyId,
  employeeId,
  forEmployee,
}: FetchTemplatesParams) => {
  const params: (string | number)[] = [mode];
  if (companyId) {
    params.push(`companyId:${companyId}`);
  }
  if (employeeId) {
    params.push(`employeeId:${employeeId}`);
  }
  if (forEmployee) {
    params.push('forEmployee');
  }
  return params.join('|');
};

const templateItemsSlice = createSlice({
  name: 'items',
  reducers: {},
  initialState: initialTemplateState,
  extraReducers: builder => {
    builder.addCase(fetchProcessTemplates.fulfilled, (state, action) => {
      state = { ...state, ...action.payload.entities.templates };
      return state;
    });
  },
});

const templateListSlice = createSlice({
  name: 'list',
  reducers: {},
  initialState: {} as Record<string, string[]>,
  extraReducers: builder => {
    builder.addCase(fetchProcessTemplates.fulfilled, (state, action) => {
      const key = getTemplatesKey(action.meta.arg);
      state[key] = action.payload.data;
      return state;
    });
  },
});

type FlowDictionary = Record<string, ProcessFlow>;
const initialFlowState: Record<string, FlowDictionary> = {};

const flowsSlice = createSlice({
  name: 'flows',
  reducers: {},
  initialState: initialFlowState,
  extraReducers: builder => {
    builder.addCase(fetchProcessTemplates.fulfilled, (state, action) => {
      const key = getTemplatesKey(action.meta.arg);
      state[key] = action.payload.entities.flows;
      return state;
    });
  },
});

type StatusMap = Record<string, ProcessStatus>;

const initialStatusesState: StatusMap = {};

export const statusesSlice = createSlice({
  name: 'statuses',
  initialState: initialStatusesState,
  reducers: {
    addStatuses: (state, action: PayloadAction<StatusMap>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProcessTemplates.fulfilled, (state, action) => {
      state = { ...state, ...action.payload.entities.statuses };
      return state;
    });
  },
});

const templateStatus = createAsyncThunkStatusSlice(fetchProcessTemplates, {
  getKey: getTemplatesKey,
});

const templates = combineReducers({
  items: templateItemsSlice.reducer,
  list: templateListSlice.reducer,
  status: templateStatus.reducer,
});

const processReducer = combineReducers({
  templates,
  flows: flowsSlice.reducer,
  statuses: statusesSlice.reducer,
});

export type ProcessState = ReturnType<typeof processReducer>;

export default processReducer;
