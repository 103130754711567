import { combineReducers, AnyAction } from 'redux';
import { names } from 'shared/actions/ui';
import { names as infoBoxesNames } from 'shared/actions/fetchHtml';
import guide, { GuideState } from 'shared/modules/guides/guideReducer';
import { small, medium } from './mobile';
import { USER_LOGOUT } from '../actions/user/logout';

interface ModeState {
  [key: string]: string | number | null;
}

interface InfoBoxState {
  [key: string]: true;
}

interface infoBox {
  image: string | null;
  imageNormal: string | null;
  id: number;
  html: string;
  css: string | null;
  title: string | null;
  subtitle: string | null;
  to: string | null;
  href: string | null;
  label: string | null;
}

export interface UiState {
  productsList: boolean;
  blurOverlay: boolean;
  bodyOverflow: boolean;
  offline: boolean;
  mode: ModeState;
  small: boolean;
  medium: boolean;
  guide: GuideState;
  infoBox: InfoBoxState;
  infoBoxes: infoBox[];
  navigation: boolean;
  idleWarning: Date | null;
  idleLogout: boolean;
  fixedNav: boolean;
}

const productsList = (state = false, action: AnyAction) => {
  if (action.type === names.toggleProductList) {
    return !state;
  }
  return state;
};

const blurOverlay = (state = false, action: AnyAction) => {
  if (action.type === names.blurOverlay) {
    return action.payload;
  }
  return state;
};

const bodyOverflow = (state = false, action: AnyAction) => {
  if (action.type === names.setBodyOverflow) {
    return action.payload || false;
  }
  return state;
};

const fixedNav = (state = true, action: AnyAction) => {
  if (action.type === names.fixedNav) {
    return action.payload;
  }
  return state;
};

const infoBoxState = (state: InfoBoxState = {}, action: AnyAction) => {
  if (action.type === names.infoBoxHide) {
    return { ...state, [action.payload]: true };
  }
  return state;
};

const infoBoxes = (state: UiState['infoBoxes'] = [], action: AnyAction) => {
  if (action.type === infoBoxesNames.success) {
    return action.payload;
  }
  return state;
};

const offline = (state = false, action: AnyAction) => {
  if (action.type === names.toggleOffline) {
    return action.payload || !state;
  }
  return state;
};

const mode = (state: ModeState = {}, action: AnyAction) => {
  if (action.type === 'MODES') {
    return { ...action.payload };
  }
  return state;
};

const navigation = (state: boolean = true, action: AnyAction) => {
  if (action.type === names.setNavigation) {
    return action.payload;
  }
  return state;
};

const idleWarning = (state: boolean = false, action: AnyAction) => {
  if (action.type === names.setIdleWarning) {
    return action.payload;
  }
  return state;
};

const idleLogout = (state: boolean = false, action: AnyAction) => {
  if (action.type === USER_LOGOUT) {
    return action?.payload?.idle || false;
  }
  return state;
};

const ui = combineReducers({
  productsList,
  navigation,
  blurOverlay,
  idleWarning,
  idleLogout,
  bodyOverflow,
  offline,
  mode,
  small,
  medium,
  guide,
  infoBox: infoBoxState,
  infoBoxes,
  fixedNav,
});

export default ui;
