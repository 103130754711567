import { Action, combineReducers } from 'redux';
import { MetaState } from 'shared/types/reducers/MetaState';
import makeStatusReducer, { StatusState } from 'shared/reducers/status';
import {
  ChartSection,
  PopularityChartSection,
  GenderChartSection,
  PieChartSection,
  ChartGranulation,
} from './ChartTypes';
import {
  insightsChartActionsNames,
  InsightChartSuccessAction,
  InsightActionParams,
  InsightChartAction,
  insightsHomeActionsNames,
  InsightHomeAction,
  InsightHomeSuccessAction,
} from './insightsActions';
import { SectionsResponse } from './useInsightsDashboard';

export interface ChallengesListState {
  list: number[];
  meta: MetaState | null;
  status: StatusState;
}

export const getInsightChartKey = ({
  type,
  companyId,
  granulation,
  locale,
  range,
}: InsightActionParams) => {
  const parts = [locale, companyId, type, granulation];

  if (granulation === ChartGranulation.custom) {
    parts.push(
      `from:${range.from?.toISOString()}|to:${range.to?.toISOString()}`,
    );
  }

  return parts.join('|');
};

const data = (
  state:
    | ChartSection
    | GenderChartSection
    | PieChartSection
    | PopularityChartSection
    | null = null,
  action: Action<any>,
) => {
  if (action.type === insightsChartActionsNames.success) {
    const { payload } = action as InsightChartSuccessAction;
    return payload.data;
  }

  return state;
};

const chartsItem = combineReducers({
  data,
  status: makeStatusReducer(insightsChartActionsNames),
});

const homeData = (
  state: SectionsResponse | null = null,
  action: Action<any>,
) => {
  if (action.type === insightsHomeActionsNames.success) {
    const { payload } = action as InsightHomeSuccessAction;
    return payload.data;
  }

  return state;
};

const homeItem = combineReducers({
  data: homeData,
  status: makeStatusReducer(insightsHomeActionsNames),
});

export type ChartStateItem = ReturnType<typeof chartsItem>;

export interface ChartsState {
  [key: string]: ChartStateItem;
}

const chartActions = Object.values(insightsChartActionsNames);
const charts = (state: ChartsState = {}, action: Action<any>) => {
  if (chartActions.includes(action.type)) {
    const { payload } = action as InsightChartAction;
    const key = getInsightChartKey(payload.params);

    return {
      ...state,
      [key]: chartsItem(state[key], action),
    };
  }
  return state;
};

const chartHomeActions = Object.values(insightsHomeActionsNames);

export type InsightsHomeItem = ReturnType<typeof homeItem>;

export interface InsightsHomeState {
  [key: string]: InsightsHomeItem;
}
const home = (state: InsightsHomeState = {}, action: Action<any>) => {
  if (chartHomeActions.includes(action.type)) {
    const { payload } = action as InsightHomeAction;
    const key = payload.params.company;
    return {
      ...state,
      [key]: homeItem(state[key], action),
    };
  }
  return state;
};

const insights = combineReducers({
  charts,
  home,
});

export type InsightsState = ReturnType<typeof insights>;

export default insights;
