/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Action } from 'redux';
import { MetaState } from 'shared/types/reducers/MetaState';
import {
  setChallengeGroupsName,
  setChallengeGroupsAction,
  updateChallengeGroupsName,
  updateChallengeGroups,
} from './challengeGroupsAction';

interface ChallengeGroupsState {
  [key: number]: {
    data: number[];
    meta?: MetaState;
  };
}

const challengeGroups = (
  state: ChallengeGroupsState = {},
  action: Action<any>,
): ChallengeGroupsState => {
  if (action.type === setChallengeGroupsName) {
    const { payload } = action as ReturnType<typeof setChallengeGroupsAction>;
    const item = state[payload.challengeId];
    const data =
      payload.meta.page === 1 ? payload.data : [...payload.data, ...item.data];
    return {
      ...state,
      [payload.challengeId]: {
        data,
        meta: payload.meta,
      },
    };
  }

  if (action.type === updateChallengeGroupsName) {
    const { payload } = action as ReturnType<typeof updateChallengeGroups>;
    const { challengeId, update } = payload;
    return produce(state, draft => {
      const item = state[payload.challengeId];

      if (!item) {
        draft[challengeId] = {
          data: update.ids,
        };
        return;
      }
      draft[challengeId].data = update.ids;
    });
  }

  return state;
};

export default challengeGroups;
