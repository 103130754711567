import errorLogger from 'shared/utils/errorLogger';
import get from 'lodash/get';
import notify from 'shared/actions/notify';
import axios from 'axios';
import { asyncNames, createActions } from '../utils';
// import { normalize } from 'normalizr';

export const names = asyncNames('FETCH_PURCHASES');
export const actions = createActions(names);

// const fetchPurchases = getFetchAction(actions, 'api_purchases_get', false);

const fetchPurchases = (params = {}) => async dispatch => {
  dispatch(actions.request({ params }));
  try {
    const response = await axios(
      AppRouting.generate(
        params.employee
          ? 'api_get_company_employee_purchases'
          : 'api_get_purchases',
        params,
      ),
    );
    await dispatch(actions.success({ params, data: response.data }));
    return response.data;
  } catch (error) {
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||error|||default'),
    );

    dispatch(notify({ message }));
    dispatch(actions.failure({ params, error }));
    return error;
  }
};

export default fetchPurchases;
