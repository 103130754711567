import padEnd from 'lodash/padEnd';

export const getDecimalCurrency = value => Math.ceil(value * 100);

export const roundCurrency = value => getDecimalCurrency(value) / 100;

const fallback = {
  format: v => {
    const [round, decimal = '00'] = `${getDecimalCurrency(v) / 100}`.split('.');
    return `${round},${padEnd(decimal, 2, '0')} zł`;
  },
};

const getCurrencyFormatter = () => {
  try {
    return new Intl.NumberFormat('pl', { style: 'currency', currency: 'PLN' });
  } catch (ex) {
    return fallback;
  }
};

const currencyFormatter = getCurrencyFormatter();

const currency = v => {
  const value = v ? Number(v) : 0;
  return currencyFormatter.format(value);
};

export const positiveCurrency = v => (v != null ? currency(v) : v);

export default currency;
