import { Action } from 'redux-actions';
import { KudosLimit, names, UpdateLimitAction } from './kudosActions';

interface LimitState {
  [companyId: number]: KudosLimit;
}

const limitReducer = (state: LimitState = {}, action: Action<any>) => {
  if (action.type === names.updateLimit) {
    const { payload } = action as UpdateLimitAction;
    return {
      ...state,
      [payload.companyId]: payload.limit,
    };
  }
  return state;
};

export default limitReducer;
